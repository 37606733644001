import * as React from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const NotFoundPage = () => {
  return (
    <>
      <PageSeo
        seoTitle={`Page Not Found - George Vasey`}
        seoText={null}
        seoImage={null}
      />
      <div>
        <h1>Not found</h1>
      </div>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
